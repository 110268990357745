<script setup>
import { useGraphQL } from '~/composables/useGraphQL';
import { colors } from '~/utils';
import { computed, onMounted } from 'vue';

const props = defineProps({
  perfil: {
    required: true,
    type: String,
  },
});

const {
  data: chartQueryData,
  runAsync: chartQueryAsync,
  status: chartQueryStatus,
} = useGraphQL({
  config: {
    getAccessToken: true,
    timeout: 120000,
  },
  query: 'ObterUsuariosCompromissosStackChartQuery',
});

const chartOptions = computed(() => {
  if (chartQueryStatus.value !== 'success') {
    return null;
  }
  return {
    chart: {
      animations: {
        enabled: false,
      },
      height: 350,
      stacked: true,
      toolbar: {
        show: false,
      },
      type: 'bar',
      zoom: {
        enabled: false,
      },
    },
    colors,
    series: chartQueryData.value.chart.series,
    xaxis: {
      categories: chartQueryData.value.chart.categories,
    },
  };
});

onMounted(async () => {
  await chartQueryAsync({
    perfil: props.perfil,
  });
});
</script>

<template>
  <ChartCard :status="chartQueryStatus" :options="chartOptions">
    <template #title>
      <span style="color: rgb(128 128 128); font-size: 16px"
        >USUÁRIOS / COMPROMISSOS</span
      >
    </template>
  </ChartCard>
</template>
