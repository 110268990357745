<script setup lang="ts">
</script>

<template>
  <a-row
    class="drawer-component-loading"
    style="padding-top: 16px; padding-left: 24px; padding-right: 24px"
  >
    <a-col :span="24">
      <a-skeleton
        :active="true"
        :paragraph="false"
      />
    </a-col>
  </a-row>
  <a-divider style="margin-top: 16px" />
  <div style="padding-left: 24px; padding-right: 24px; padding-bottom: 24px">
    <a-skeleton
      :active="true"
      :title="false"
      :paragraph="{ rows: 12 }"
    />
  </div>
</template>

<style lang="css">
.drawer-component-loading h3.ant-skeleton-title {
  margin: 0;
}
</style>
