import { cnpj, cpf } from 'cpf-cnpj-validator';
import dayjs from 'dayjs';
import { isNil as _isNil, isArray, mergeWith } from 'lodash-es';
import { mask, tokens } from 'maska';
import numeral from 'numeral';

export const API_URL = import.meta.env.VITE_API_URL;

export function sleep(ms)
{
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function filtrarSelectOptions(input, option)
{
  const optionLabelReady = option.label
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036F]/g, '');
  const inputReady = input
    .trim()
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036F]/g, '');
  return optionLabelReady.includes(inputReady);
}

export function filtrarTreeNodes(input, treeNode)
{
  const optionLabelReady = treeNode.props.title
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036F]/g, '');
  const inputReady = input
    .toLowerCase()
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036F]/g, '');
  return optionLabelReady.includes(inputReady);
}

export function isNullOrEmpty(s)
{
  if (s === null || s === undefined)
  {
    return true;
  }
  if (typeof s === 'string' && s.trim() === '')
  {
    return true;
  }
  return false;
}

export function error(e)
{
  const status = e?.response?.status;

  if (status === 403)
  {
    return {
      status,
      description: 'Solicite acesso ao administrador',
      error: 'Você não tem permissão',
      message: 'Você não tem permissão',
    };
  }

  return e?.response?.data?.error
    ? {
        status,
        description: e?.response?.data?.description,
        error: e?.response?.data?.error,
        message: e?.response?.data?.message,
      }
    : {
        status,
        description: 'Por favor entre em contato com o suporte',
        error: 'Tivemos um problema inesperado',
        message: 'Tivemos um problema inesperado',
      };
}

export function cpfValido(rule, value)
{
  if (!value)
  {
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject('CPF não é válido');
  }
  const ehValido = cpf.isValid(value);
  if (!ehValido)
  {
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject('CPF não é válido');
  }
  return Promise.resolve();
}

export function cnpjValido(rule, value)
{
  if (!value)
  {
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject('CNPJ não é válido');
  }
  const ehValido = cnpj.isValid(value);
  if (!ehValido)
  {
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject('CNPJ não é válido');
  }
  return Promise.resolve();
}

function formatCnpj(value)
{
  if (!value)
  {
    return value;
  }

  let v = value.replace(/\D/g, ''); // Remove tudo o que não é dígito
  v = v.replace(/^(\d{2})(\d)/, '$1.$2'); // Coloca ponto entre o segundo e o terceiro dígitos
  v = v.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3'); // Coloca ponto entre o quinto e o sexto dígitos
  v = v.replace(/\.(\d{3})(\d)/, '.$1/$2'); // Coloca uma barra entre o oitavo e o nono dígitos
  v = v.replace(/(\d{4})(\d)/, '$1-$2'); // Coloca um hífen depois do bloco de quatro dígitos
  return v;
}

function formatCpf(value)
{
  if (!value)
  {
    return value;
  }

  let v = value.replace(/\D/g, ''); // Remove tudo o que não é dígito
  v = v.replace(/(\d{3})(\d)/, '$1.$2'); // Coloca um ponto entre o terceiro e o quarto dígitos
  v = v.replace(/(\d{3})(\d)/, '$1.$2'); // Coloca um ponto entre o terceiro e o quarto dígitos
  // de novo (para o segundo bloco de números)
  v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2'); // Coloca um hífen entre o terceiro e o quarto dígitos
  return v;
}

export function formatCpfOuCnpj(value)
{
  if (!value)
  {
    return '-';
  }

  if (value.length === 11)
  {
    return formatCpf(value);
  }

  if (value.length === 14)
  {
    return formatCnpj(value);
  }

  return '-';
}

export function formatCnj(value, emptyPlaceholder = '-')
{
  if (!value || typeof value !== 'string')
  {
    return emptyPlaceholder;
  }

  if (value.length !== 20)
  {
    return value;
  }

  return mask(value, '#######-##.####.#.##.####', tokens);
}

export function formatTelefone(value)
{
  if (!value || typeof value !== 'string')
  {
    return '-';
  }

  return mask(value, '["(##) #####-####","(##) ####-####"]', tokens);
}

export function formatCep(value)
{
  if (!value || typeof value !== 'string')
  {
    return '-';
  }

  return mask(value, '#####-###', tokens);
}

export function formatDinheiro(value, emptyPlaceholder = '-')
{
  if (value === null || value === undefined || typeof value !== 'number')
  {
    return emptyPlaceholder;
  }

  return `R$ ${numeral(value).format('0,0.00')}`;
}

export function formatNumero(value)
{
  if (value === null || value === undefined || typeof value !== 'number')
  {
    return '-';
  }

  return `${numeral(value).format('0,0.00')}`;
}

export function getGoogleDriveUrl(fileId)
{
  // return `https://drive.google.com/uc?id=${fileId}&export=download`;
  return `https://drive.google.com/file/d/${fileId}/view`;
}

export function onlyDate(value)
{
  if (value === null || value === undefined || typeof value !== 'string')
  {
    return '-';
  }

  return value?.split(' ')[0];
}

export async function objectValidator(ruleObject, value)
{
  if (_isNil(value?.key))
  {
    return Promise.reject();
  }

  return Promise.resolve();
}

export function isNil(s)
{
  return _isNil(s);
}

export function isNilOrEmpty(s)
{
  if (_isNil(s))
  {
    return true;
  }

  if (typeof s === 'string')
  {
    if (s.trim() === '')
    {
      return true;
    }
  }

  return false;
}

export function simOuNao(s)
{
  if (_isNil(s))
  {
    return 'Não';
  }

  if (typeof s === 'boolean')
  {
    if (s)
    {
      return 'Sim';
    }
  }

  return 'Não';
}

export function fText(s)
{
  if (_isNilOrEmpty(s))
  {
    return '-';
  }

  return s;
}

export function onlyDate2(value)
{
  if (_isNilOrEmpty(value) || typeof value !== 'string')
  {
    return null;
  }

  return value?.split(' ')[0];
}

export function dateFormatOr(value, format = 'DD/MM/YYYY', orValue = '')
{
  if (_isNilOrEmpty(value) || typeof value !== 'string')
  {
    return orValue;
  }

  const formattedDate = dayjs(value, 'DD/MM/YYYY HH:mm:ss.SSS').format(format);

  if (formattedDate === 'Invalid Date')
  {
    return orValue;
  }

  return formattedDate;
}

export const colors = [
  '#267278',
  '#65338d',
  '#4770b3',
  '#d21f75',
  '#3b3689',
  '#50aed3',
  '#48b24f',
  '#e57438',
  '#569dd2',
  '#569d79',
  '#e4b031',
  '#84d2f4',
  '#cad93f',
  '#f5c8af',
  '#9ac483',
  '#9e9ea2',
];

export function desabilitarDatasPraTras(current)
{
  return (
    current
    && (current.day() === 0
    || current.day() === 6
    || current < dayjs().subtract(1, 'day').endOf('day'))
  );
}

export function arrayToNestedObject(arr, leafValue = null)
{
  let obj = {};
  const root = obj;

  if (!arr)
  {
    return obj;
  }

  for (let i = 0; i < arr.length; i++)
  {
    if (i === arr.length - 1)
    {
      obj[arr[i]] = leafValue;
    }
    else
    {
      obj[arr[i]] = {};
      obj = obj[arr[i]];
    }
  }

  return root;
}

export function deepIntParaTextoComQuatroOpcoes(v)
{
  switch (v)
  {
    case 0:
      return 'NÃO (0)';
    case 1:
      return 'SIM (1)';
    case 2:
      return 'AGUARDANDO (2)';
    case 3:
      return 'NÃO APLICÁVEL (3)';
    default:
      return '';
  }
}

export function deepIntParaTextoComTresOpcoes(v)
{
  switch (v)
  {
    case 0:
      return 'NÃO (0)';
    case 1:
      return 'SIM (1)';
    case 2:
      return 'NÃO SE APLICA (2)';
    default:
      return '';
  }
}

export function deepIntParaTextoComDuasOpcoes(v)
{
  switch (v)
  {
    case 0:
      return 'NÃO (0)';
    case 1:
      return 'SIM (1)';
    default:
      return '';
  }
}

export function deepRegiao(v)
{
  switch (v)
  {
    case 1:
      return 'NORTE (1)';
    case 2:
      return 'NORDESTE (2)';
    case 3:
      return 'CENTRO-OESTE (3)';
    case 4:
      return 'SUDESTE (4)';
    case 5:
      return 'SUL (5)';
    default:
      return '';
  }
}

export function formatDate(
  value,
  timezone,
  format = 'DD/MM/YYYY',
  emptyPlaceholder = '',
)
{
  if (value === null || value === undefined)
  {
    return emptyPlaceholder;
  }

  return dayjs.utc(value).tz(timezone).format(format);
}

export function onlyNumbers(x)
{
  return x.replace(/\D/g, '');
}

export function dashedToUppercase(str)
{
  return str
    .split('-')
    .map(x => x[0].toUpperCase() + x.substring(1))
    .join('');
}

function customizer(objValue, srcValue)
{
  if (isArray(objValue))
  {
    return objValue.concat(srcValue);
  }
}

export function mergeWithArrayConcat(a, b)
{
  return mergeWith(a, b, customizer);
}

export function onlyAlphanumeric(str)
{
  if (!str)
  {
    return str;
  }

  return str.replace(/[^a-z0-9]/gi, '');
}
