<script setup lang="ts">
interface Props
{
  initialModel?: any
}

const props = withDefaults(defineProps<Props>(), {
  initialModel: {},
});

const emit = defineEmits(['close']);
</script>

<template>
  <CommandForm
    command="CancelarFluxoCommand"
    :show-submit-button-icon="false"
    :initial-model="props.initialModel"
  >
    <template #form="{ model }">
      <a-alert
        :message="model.codigos.length > 1
          ? 'Deseja realmente cancelar os fluxos?'
          : 'Deseja realmente cancelar o fluxo?'"
        type="warning"
        show-icon
        style="margin-bottom: 24px"
      />

      <a-form-item
        label="Justificativa"
        name="justificativa"
        :rules="[{ required: true, message: 'Justificativa é obrigatório' }]"
      >
        <a-textarea
          v-model:value="model.justificativa"
          :rows="4"
        />
      </a-form-item>
    </template>
    <template #submitSuccess>
      <a-result
        status="success"
        title="Fluxos cancelados com sucesso!"
        sub-title="Agora você pode fechar essa janela."
      >
        <template #extra>
          <a-button @click="() => emit('close')">
            Fechar
          </a-button>
        </template>
      </a-result>
    </template>
  </CommandForm>
</template>
