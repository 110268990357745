query jobCompromissoDatatable(
  $where: JobModelFilterInput
  $order: [JobModelSortInput!]
  $skip: Int
  $take: Int
) {
  data: jobsProjectedOffsetPagination(
    where: $where
    order: $order
    skip: $skip
    take: $take
  ) {
    items {
      codigo
      progresso
      compromisso {
        codigo
        status
        tipo
        tipoText
        dataInicio
        dataInicioText
        dataConclusao
        dataConclusaoText
        responsavel {
          nome
        }
        data
      }
    }
    totalCount
  }
}
