<script setup lang="ts">
import { ref } from 'vue';
import type {
  GraphqlDatatableColumn,
} from '~/components/GqlDatatable.vue';
import jobCompromissoDatatable from '~/graphql/jobCompromissoDatatable.graphql';
import { useDrawerStore } from '~/stores/drawer';

interface Props
{
  disabledFilters?: string[]
  variables?: any
  keepHistory?: boolean
  enableRowSelection?: boolean
  reloadInterval?: number
}

const props = defineProps<Props>();
const emit = defineEmits(['load', 'reload']);

const gqlVariables = ref<any>(props.variables ? { ...props.variables } : {});
const tableRef = ref<any>(null);

const colunas = [
  {
    key: 'compromisso',
    title: 'COMPROMISSO',
    width: 120,
  },
  {
    key: 'status',
    dataIndex: 'statusText',
    title: 'STATUS',
    width: 120,
  },
  {
    key: 'responsavel',
    title: 'RESPONSÁVEL',
    width: 120,
  },
  {
    dataIndex: ['compromisso', 'dataInicioText'],
    key: 'dataInicio',
    sortBy: ['compromisso', 'dataInicio'],
    sorter: true,
    title: 'SOLICITADO EM',
    width: 120,
    defaultSortOrder: 'descend',
  },
  {
    dataIndex: ['compromisso', 'dataConclusaoText'],
    key: 'dataConclusao',
    sortBy: ['compromisso', 'dataConclusao'],
    sorter: true,
    title: 'CONCLUÍDO EM',
    width: 120,
  },
] as GraphqlDatatableColumn[];

const drawerStore = useDrawerStore();

function abrirCompromisso(compromissoCodigo: string)
{
  drawerStore.push2({
    componentName: 'CompromissoView',
    onClose: async () =>
    {
      if (tableRef.value)
      {
        await tableRef.value?.reloadAsync();
      }
    },
    params: { compromisso: { codigo: compromissoCodigo } },
  });
}

defineExpose({
  reload: async () =>
  {
    if (tableRef.value)
    {
      await tableRef.value?.reloadAsync();
    }
  },
  getDataSource: async () =>
  {
    if (tableRef.value)
    {
      await tableRef.value?.getDataSource();
    }
  },
});
</script>

<template>
  <GqlDatatable
    ref="tableRef"
    :query="jobCompromissoDatatable"
    :columns="colunas"
    :variables="gqlVariables"
    row-key="codigo"
    :page-size-options="['10', '20', '30', '40', '50']"
    :reload-interval="props.reloadInterval"
    @load="(dataSource: any) => emit('load', dataSource)"
    @reload="(dataSource: any) => emit('reload', dataSource)"
  >
    <template #compromissoColumn="{ record, column }">
      <slot
        name="compromissoColumn"
        :column="column"
        :record="record"
      >
        <a
          v-if="record.compromisso.status !== 1"
          href="#"
          style="text-transform: uppercase"
          @click.prevent="abrirCompromisso(record.compromisso.codigo)"
        >{{ record.compromisso.tipoText }}</a>
        <span v-else>{{ record.compromisso.tipoText }}</span>
      </slot>
    </template>
    <template #responsavelColumn="{ record }">
      <span style="text-transform: uppercase">{{ record.compromisso.responsavel?.nome }}
      </span>
    </template>
    <template #statusColumn="{ record }">
      <a-progress
        v-if="record.compromisso.status === 1"
        :percent="record.progresso ? Math.floor(record.progresso) : 0"
        size="small"
      />
      <a-progress
        v-else-if="record.compromisso.status === 3 && record.compromisso.data?.erro"
        :percent="100"
        status="exception"
        size="small"
      />
      <a-progress
        v-else
        :percent="100"
        size="small"
      />
    </template>
  </GqlDatatable>
</template>
