<script setup>
import { computed, onMounted, onUnmounted, reactive, ref } from 'vue';
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  StopOutlined,
} from '@ant-design/icons-vue';
import { useAuthStore } from '~/stores/auth';
import { useDrawerStore } from '~/stores/drawer';
import { isNilOrEmpty } from '~/utils';
import { useGraphQL } from '~/composables/useGraphQL';
import EnumFluxoStatus from '~/enums/EnumFluxoStatus';
import EnumPerfil from '~/enums/EnumPerfil';

const props = defineProps({
  andamentoCodigo: {
    type: String,
    default: null,
  },
});

const emit = defineEmits(['close']);

const {
  status: fluxosQueryStatus,
  data: fluxosQueryData,
  runAsync: fluxosQueryAsync,
} = useGraphQL({
  query: 'FluxosQuery',
  config: {
    getAccessToken: true,
    timeout: 120000,
  },
});

const obterFluxosStatus = computed(() =>
{
  return fluxosQueryStatus.value;
});

const formModel = reactive({
  codigoFluxoParaCancelar: null,
  justificativaCancelamento: null,
});
const drawerStore = useDrawerStore();
const authStore = useAuthStore();
const cancelarFluxoModalVisible = ref(false);
const colunas = [
  {
    key: 'pasta',
    title: 'PASTA',
  },
  {
    key: 'numeroCnj',
    title: 'NÚMERO CNJ',
  },
  {
    key: 'responsavelProcessual',
    title: 'RESPONSÁVEL PROCESSUAL',
  },
  {
    key: 'responsavelNegocial',
    title: 'RESPONSÁVEL NEGOCIAL',
  },
  {
    key: 'contrario',
    title: 'CONTRÁRIO',
  },
];
const $state = reactive({
  fluxos: [],
});
let unsubscribe = null;
let drawerId;

function abrirFluxoDrawer(codigo)
{
  drawerId = drawerStore.push2({
    componentName: 'FluxoView',
    params: { codigoFluxo: codigo },
  });
}

function abrirCancelarFluxo(codigoFluxo)
{
  drawerStore.push2({
    componentName: 'DrawerForm',
    params: {
      form: 'CancelarFluxoForm',
      title: () => 'Cancelar fluxo',
      roles: [],
      initialModel: {
        codigos: [codigoFluxo],
      },
    },
    onClose: async () =>
    {
      await uiFluxosQueryAsync();
    },
  });
}

async function uiFluxosQueryAsync()
{
  if (props.andamentoCodigo !== null)
  {
    await fluxosQueryAsync({
      filter: {
        andamentoCodigo: props.andamentoCodigo,
      },
      order: {
        dataInicio: 'ASC',
      },
    });

    if (fluxosQueryStatus.value === 'success')
    {
      $state.fluxos = fluxosQueryData.value.fluxos;
    }
  }
}

onMounted(async () =>
{
  await uiFluxosQueryAsync();

  unsubscribe = drawerStore.$subscribe(async (mutation, state) =>
  {
    if (
      state.lastAction === 'close'
      && state.lastClosedDrawer.id === drawerId
    )
    {
      await uiFluxosQueryAsync();
    }
  });
});

onUnmounted(() =>
{
  if (unsubscribe)
  {
    unsubscribe();
  }
});
</script>

<template>
  <div
    class="fluxos-view"
    style="padding-bottom: 24px"
  >
    <DrawerTitle @close="() => emit('close')">
      <template #title>
        <a-skeleton
          v-if="obterFluxosStatus === 'loading'"
          :loading="true"
          :paragraph="false"
          :active="true"
          :title="{ width: '75%' }"
        />
        <span
          v-if="obterFluxosStatus === 'success'"
          style="font-size: 16px; font-weight: 500; line-height: 35px"
        >
          FLUXOS</span>
      </template>
    </DrawerTitle>
    <div style="padding: 0 24px">
      <a-skeleton
        v-if="obterFluxosStatus === 'loading'"
        :loading="true"
        :active="true"
        :paragraph="{ rows: 12 }"
      />
      <ErrorResult
        v-else-if="obterFluxosStatus === 'error'"
        :tentar-novamente-fn="uiFluxosQueryAsync"
      />
      <div v-else-if="obterFluxosStatus === 'success'">
        <h3>Histórico de fluxos</h3>

        <a-card style="margin-top: 16px">
          <Empty
            v-if="$state.fluxos.length === 0"
            description="Nenhum fluxo encontrado"
          />
          <a-row
            v-else
            justify="center"
          >
            <a-col :span="24">
              <a-timeline
                :reverse="true"
                style="margin-top: 16px"
              >
                <a-timeline-item style="padding: 0; height: 20px">
                  <br>
                </a-timeline-item>
                <a-timeline-item
                  v-for="(fluxo, index) in $state.fluxos"
                  :key="fluxo.codigo"
                >
                  <template #dot>
                    <a-tooltip>
                      <template #title>
                        {{ fluxo.statusText }}
                      </template>
                      <ClockCircleOutlined
                        v-if="fluxo.statusValue === EnumFluxoStatus.EmAndamento"
                        style="font-size: 16px"
                      />
                      <CheckCircleOutlined
                        v-if="fluxo.statusValue === EnumFluxoStatus.Concluido"
                        style="font-size: 16px; color: #52c41a"
                      />
                      <CloseCircleOutlined
                        v-if="fluxo.statusValue === EnumFluxoStatus.Cancelado"
                        style="font-size: 16px; color: #df4c45"
                      />
                      <StopOutlined
                        v-if="fluxo.statusValue === EnumFluxoStatus.Falho"
                        style="font-size: 16px; color: #df4c45"
                      />
                    </a-tooltip>
                  </template>
                  <a-row>
                    <a-col :span="24">
                      <a-tooltip>
                        <template #title>
                          Ver compromissos do fluxo
                        </template>
                        <span>
                          <a
                            href="#"
                            :style="
                              fluxo.statusValue === EnumFluxoStatus.Cancelado
                                ? {
                                  textDecorationLine: 'line-through',
                                  color: 'grey',
                                  textTransform: 'uppercase',
                                }
                                : { textTransform: 'uppercase' }
                            "
                            @click.prevent="
                              () => abrirFluxoDrawer(fluxo.codigo)
                            "
                          >{{ fluxo.tipoText }}
                          </a>

                          <span style="font-weight: 500; color: #858383">
                            ({{ index + 1 }})</span>

                          <span
                            v-if="
                              fluxo.statusValue === EnumFluxoStatus.Cancelado
                            "
                          >
                            (CANCELADO POR: <b>{{ fluxo.canceladoPor.nome }}</b>)
                          </span>
                          <span
                            v-if="fluxo.statusValue === EnumFluxoStatus.Falho"
                          >
                            (DEFINIDO COMO NÃO ATENDIDO POR:
                            <b>{{ fluxo.falhadoPor.nome }}</b>)
                          </span>
                        </span>
                      </a-tooltip>
                      <span
                        v-if="
                          fluxo.statusValue === EnumFluxoStatus.EmAndamento
                            && authStore.temPerfilAcesso(EnumPerfil.CancelarFluxo)
                        "
                      >
                        <a-button
                          size="small"
                          style="margin: 0 8px"
                          @click="() => abrirCancelarFluxo(fluxo.codigo)"
                        >Cancelar</a-button>
                      </span>
                      <a-alert
                        v-if="
                          fluxo.statusValue === EnumFluxoStatus.Cancelado
                            && !isNilOrEmpty(fluxo.justificativaCancelamento)
                        "
                        type="error"
                        style="margin: 8px 0"
                      >
                        <template #message>
                          <span
                            style="
                              word-wrap: break-word;
                              white-space: pre-wrap;
                              word-break: break-word;
                            "
                          >{{ fluxo.justificativaCancelamento }}</span>
                        </template>
                      </a-alert>
                      <br v-else>
                      <span class="span-key">INICIADO POR: </span>
                      <span class="span-value">{{
                        fluxo.iniciadoPor.nome
                      }}</span>
                      <br>
                      <span class="span-key">Iniciado em: </span>
                      <span class="span-value">{{ fluxo.dataInicioText }}</span>
                      <div
                        v-if="
                          fluxo.statusValue === EnumFluxoStatus.Concluido
                            || fluxo.statusValue === EnumFluxoStatus.Cancelado
                        "
                      >
                        <span class="span-key">{{ fluxo.statusText }} em:
                        </span>
                        <span
                          v-if="fluxo.dataConclusaoText !== null"
                          class="span-value"
                        >{{ fluxo.dataConclusaoText }}</span>
                        <span
                          v-else
                          class="span-value"
                        >-</span>
                      </div>
                    </a-col>
                  </a-row>
                  <a-row>
                    <a-col :span="24" />
                  </a-row>
                </a-timeline-item>
              </a-timeline>
            </a-col>
          </a-row>
        </a-card>
      </div>
    </div>
  </div>
</template>

<style lang="less">
.fluxos-view {
  & .ant-skeleton-content .ant-skeleton-title {
    margin-top: 10px;
  }
}
</style>
