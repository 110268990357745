<script setup lang="ts">
import { onMounted } from 'vue';
import { FolderOutlined } from '@ant-design/icons-vue';
import { useDrawerStore } from '~/stores/drawer';

interface Props
{
  compromisso?: any
}

const props = defineProps<Props>();

const emit = defineEmits(['loaded']);

const drawerStore = useDrawerStore();

function abrirCompromisso(compromissoCodigo: string)
{
  drawerStore.push2({
    componentName: 'CompromissoView',
    params: { compromisso: { codigo: compromissoCodigo } },
  });
}

onMounted(() =>
{
  emit('loaded');
});
</script>

<template>
  <a-alert
    v-if="props.compromisso.data?.erro"
    type="error"
    :message="props.compromisso.data.erro"
    show-icon
  />
  <a-descriptions
    v-else-if="props.compromisso.data?.execucoes"
    :column="1"
    bordered
    style="margin-top: 24px"
  >
    <a-descriptions-item
      v-for="execucao in props.compromisso.data.execucoes"
      :key="execucao.compromissoCodigo"
      :span="3"
    >
      <FolderOutlined style="margin-right: 8px" />
      <a
        href="#"
        @click.prevent="() => abrirCompromisso(execucao.compromissoCodigo)"
      >{{ execucao.gcpj }}</a> - {{ execucao.sucesso ? 'OK' : execucao.mensagemErro }}
    </a-descriptions-item>
  </a-descriptions>
</template>
