<script setup lang="ts">
import preencherInformacoesNecessariasDistribuicaoPastaQuery from '~/graphql/preencherInformacoesNecessariasDistribuicaoPasta.graphql';
import usuarioSelect from '~/graphql/usuarioSelect.graphql';
import { isNullOrEmpty } from '~/utils';
import agenciaSelect from '~/graphql/agenciaSelect.graphql';
import pessoaSelect from '~/graphql/pessoaSelect.graphql';
import carteiraSelect from '~/graphql/carteiraSelect.graphql';

interface Props
{
  compromisso: any
}

const props = defineProps<Props>();

const emit = defineEmits(['close']);
</script>

<template>
  <GqlView
    :query="preencherInformacoesNecessariasDistribuicaoPastaQuery"
    :variables="{
      where: {
        codigo: {
          eq: props.compromisso.codigo,
        },
      },
    }"
  >
    <template #success="{ data } : { data: any }">
      <CompromissoJobCommandForm
        command="PreencherInformacoesNecessariasDistribuicaoPastaCommand"
        :show-upload-files="false"
        :initial-model="{
          compromissoCodigos: props.compromisso ? [props.compromisso.codigo] : null,
          conta: data?.model.fluxo.pasta.conta,
          numeroCnj: data?.model.fluxo.pasta.numeroCnj,
          agenciaEmail: data?.model.fluxo.pasta.agencia2.email,
          agencia: data?.model.fluxo.pasta.agencia2 ? {
            value: data?.model.fluxo.pasta.agencia2.codigo,
            label: data?.model.fluxo.pasta.agencia2.label,
          } : null,
          contrarioPrincipal: data?.model.fluxo.pasta.contrarioPrincipal ? {
            value: data?.model.fluxo.pasta.contrarioPrincipal.codigo,
            label: data?.model.fluxo.pasta.contrarioPrincipal.nome,
          } : null,
          responsavelNegocial: data?.model.fluxo.pasta.responsavelNegocial ? {
            value: data?.model.fluxo.pasta.responsavelNegocial.codigo,
            label: data?.model.fluxo.pasta.responsavelNegocial.nome,
          } : null,
          responsavelProcessual: data?.model.fluxo.pasta.responsavelProcessual ? {
            value: data?.model.fluxo.pasta.responsavelProcessual.codigo,
            label: data?.model.fluxo.pasta.responsavelProcessual.nome,
          } : null,
          gerenteNegocialResponsavel: data?.model.fluxo.pasta.gerenteNegocialResponsavel ? {
            value: data?.model.fluxo.pasta.gerenteNegocialResponsavel.codigo,
            label: data?.model.fluxo.pasta.gerenteNegocialResponsavel.nome,
          } : null,
          carteira: data?.model.fluxo.pasta.carteiraContratoVigente?.carteira ? {
            value: data?.model.fluxo.pasta.carteiraContratoVigente.carteira.codigo,
            label: data?.model.fluxo.pasta.carteiraContratoVigente.carteira.label,
          } : null,
          contrato: data?.model.fluxo.pasta.carteiraContratoVigente
            ? data.model.fluxo.pasta.carteiraContratoVigente.contrato
            : null,
        }"
        @close="() => emit('close')"
      >
        <template #form="{ model }">
          <a-form-item
            label="Agência"
            name="agencia"
            :rules="{
              message: 'Agência é obrigatória',
              required: true,
              trigger: 'change',
            }"
          >
            <GqlSelect
              v-model:value="model.agencia"
              :query="agenciaSelect"
              placeholder="Selecione a agência"
              :allow-clear="true"
            />
          </a-form-item>
          <a-form-item
            label="Email da agência"
            name="agenciaEmail"
            :rules="{
              message: 'Email da agência é obrigatório',
              required: true,
              trigger: 'change',
            }"
          >
            <a-input v-model:value="model.agenciaEmail" />
          </a-form-item>
          <a-form-item
            label="Conta"
            name="conta"
            :rules="{
              message: 'Conta é obrigatória',
              required: true,
              trigger: 'change',
            }"
          >
            <a-input v-model:value="model.conta" />
          </a-form-item>
          <a-form-item
            name="carteira"
            label="Carteira"
            :rules="{
              message: 'Carteira é obrigatória',
              required: true,
              trigger: 'change',
            }"
          >
            <GqlSelect
              v-model:value="model.carteira"
              :query="carteiraSelect"
              placeholder="Selecione a carteira"
              :allow-clear="true"
            />
          </a-form-item>
          <a-form-item
            label="Contrato"
            name="contrato"
            :rules="{
              message: 'Contrato é obrigatório',
              required: true,
              trigger: 'change',
            }"
          >
            <a-input v-model:value="model.contrato" />
          </a-form-item>
          <a-form-item
            name="contrarioPrincipal"
            label="Contrário principal"
            :rules="{
              message: 'Contrário principal é obrigatório',
              required: true,
              trigger: 'change',
            }"
          >
            <GqlSelect
              v-model:value="model.contrarioPrincipal"
              :query="pessoaSelect"
              placeholder="Selecione o contrário"
              :allow-clear="true"
            />
          </a-form-item>
          <a-form-item
            name="responsavelNegocial"
            label="Responsável negocial"
            :rules="{
              message: 'Responsável negocial é obrigatório',
              required: true,
              trigger: 'change',
            }"
          >
            <GqlSelect
              v-model:value="model.responsavelNegocial"
              :query="usuarioSelect"
              :variables="{
                where: {
                  ativo: {
                    eq: true,
                  },
                },
                order: [
                  {
                    nome: 'ASC',
                  },
                ],
              }"
              placeholder="Selecione o responsável"
              :allow-clear="true"
            />
          </a-form-item>
          <a-form-item
            name="gerenteNegocialResponsavel"
            label="Gerente negocial responsável"
            :rules="{
              message: 'Gerente negocial responsável é obrigatório',
              required: true,
              trigger: 'change',
            }"
          >
            <GqlSelect
              v-model:value="model.gerenteNegocialResponsavel"
              :query="usuarioSelect"
              :variables="{
                where: {
                  ativo: {
                    eq: true,
                  },
                },
                order: [
                  {
                    nome: 'ASC',
                  },
                ],
              }"
              placeholder="Selecione o gerente"
              :allow-clear="true"
            />
          </a-form-item>
          <a-form-item
            name="responsavelProcessual"
            label="Responsável processual"
            :rules="!isNullOrEmpty(model.numeroCnj) ? {
              message: 'Responsável processual é obrigatório',
              required: true,
              trigger: 'change',
            } : undefined"
          >
            <GqlSelect
              v-model:value="model.responsavelProcessual"
              :query="usuarioSelect"
              :variables="{
                where: {
                  ativo: {
                    eq: true,
                  },
                },
                order: [
                  {
                    nome: 'ASC',
                  },
                ],
              }"
              placeholder="Selecione o responsável"
              :allow-clear="true"
            />
          </a-form-item>
        </template>
      </CompromissoJobCommandForm>
    </template>
  </GqlView>
</template>
