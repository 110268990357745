<script setup lang="ts">
import { onMounted } from 'vue';

interface Props
{
  compromisso?: any
}

const props = defineProps<Props>();

const emit = defineEmits(['loaded']);

onMounted(() =>
{
  emit('loaded');
});
</script>

<template>
  <a-descriptions
    v-if="props.compromisso.data.dados"
    :column="3"
    :label-style="{ width: '35%' }"
    bordered
  >
    <a-descriptions-item
      label="GCPJ"
      :span="3"
    >
      {{ props.compromisso.data.dados.gcpj }}
    </a-descriptions-item>
    <a-descriptions-item
      label="ENVOLVIDO"
      :span="3"
    >
      {{ props.compromisso.data.dados.envolvido }}
    </a-descriptions-item>
    <a-descriptions-item
      label="DATA_CADASTRO"
      :span="3"
    >
      {{ props.compromisso.data.dados.dataCadastro }}
    </a-descriptions-item>
    <a-descriptions-item
      label="DATA_AJUIZAMENTO"
      :span="3"
    >
      {{ props.compromisso.data.dados.dataAjuizamento }}
    </a-descriptions-item>
    <a-descriptions-item
      label="TIPO_ACAO"
      :span="3"
    >
      {{ props.compromisso.data.dados.tipoAcao }}
    </a-descriptions-item>
    <a-descriptions-item
      label="PROCESSO"
      :span="3"
    >
      {{ props.compromisso.data.dados.dataCadastro }}
    </a-descriptions-item>
    <a-descriptions-item
      label="ORGAO_JULGADOR"
      :span="3"
    >
      {{ props.compromisso.data.dados.orgaoJulgador }}
    </a-descriptions-item>
    <a-descriptions-item
      label="COMARCA"
      :span="3"
    >
      {{ props.compromisso.data.dados.comarca }}
    </a-descriptions-item>
    <a-descriptions-item
      label="UF"
      :span="3"
    >
      {{ props.compromisso.data.dados.uf }}
    </a-descriptions-item>
    <a-descriptions-item
      label="AGENCIA"
      :span="3"
    >
      {{ props.compromisso.data.dados.agencia }}
    </a-descriptions-item>
    <a-descriptions-item
      label="AGENCIA_EMAIL"
      :span="3"
    >
      {{ props.compromisso.data.dados.agenciaEmail }}
    </a-descriptions-item>
    <a-descriptions-item
      label="CONTA"
      :span="3"
    >
      {{ props.compromisso.data.dados.conta }}
    </a-descriptions-item>
    <a-descriptions-item
      label="CARTEIRA"
      :span="3"
    >
      {{ props.compromisso.data.dados.carteira }}
    </a-descriptions-item>
    <a-descriptions-item
      label="CONTRATO"
      :span="3"
    >
      {{ props.compromisso.data.dados.contrato }}
    </a-descriptions-item>
    <a-descriptions-item
      label="DEJUR"
      :span="3"
    >
      {{ props.compromisso.data.dados.dejur }}
    </a-descriptions-item>
    <a-descriptions-item
      label="ADVOGADO_BANCO"
      :span="3"
    >
      {{ props.compromisso.data.dados.advogadoBanco }}
    </a-descriptions-item>
    <a-descriptions-item
      label="VALOR_ESTOQUE"
      :span="3"
    >
      {{ props.compromisso.data.dados.valorEstoque ? `R$ ${props.compromisso.data.dados.valorEstoque}` : '' }}
    </a-descriptions-item>
    <a-descriptions-item
      label="RESPONSAVEL_PROCESSUAL"
      :span="3"
    >
      {{ props.compromisso.data.dados.responsavelProcessual }}
    </a-descriptions-item>
    <a-descriptions-item
      label="RESPONSAVEL_NEGOCIAL"
      :span="3"
    >
      {{ props.compromisso.data.dados.responsavelNegocial }}
    </a-descriptions-item>
    <a-descriptions-item
      label="GERENTE_NEGOCIAL_RESPONSAVEL"
      :span="3"
    >
      {{ props.compromisso.data.dados.gerenteNegocialResponsavel }}
    </a-descriptions-item>
    <a-descriptions-item
      label="ERROS"
      :span="3"
    >
      <ul>
        <li
          v-for="erro in props.compromisso.data.erros"
          :key="erro"
        >
          {{ erro }}
        </li>
      </ul>
    </a-descriptions-item>
  </a-descriptions>

  <a-descriptions
    v-else
    :column="3"
    :label-style="{ width: '15%' }"
    bordered
  >
    <a-descriptions-item
      label="GCPJ"
      :span="3"
    >
      {{ props.compromisso.data.gcpj }}
    </a-descriptions-item>
    <a-descriptions-item
      label="CONTA"
      :span="3"
    >
      {{ props.compromisso.data.conta }}
    </a-descriptions-item>
    <a-descriptions-item
      label="AGÊNCIA"
      :span="3"
    >
      {{ props.compromisso.data.agencia }}
    </a-descriptions-item>
    <a-descriptions-item
      label="CARTEIRA"
      :span="3"
    >
      {{ props.compromisso.data.carteira }}
    </a-descriptions-item>
    <a-descriptions-item
      label="CONTRATO"
      :span="3"
    >
      {{ props.compromisso.data.contrato }}
    </a-descriptions-item>
    <a-descriptions-item
      label="CLIENTE"
      :span="3"
    >
      {{ props.compromisso.data.cliente }}
    </a-descriptions-item>
    <a-descriptions-item
      label="DEJUR"
      :span="3"
    >
      {{ props.compromisso.data.dejur }}
    </a-descriptions-item>
    <a-descriptions-item
      label="ADVOGADO BANCO"
      :span="3"
    >
      {{ props.compromisso.data.advogadoBanco }}
    </a-descriptions-item>
  </a-descriptions>
</template>
