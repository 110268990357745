<script setup lang="ts">
import { CaretRightOutlined, ReloadOutlined, RobotOutlined } from '@ant-design/icons-vue';
import { computed, ref } from 'vue';
import { useDrawerStore } from '~/stores/drawer';

const historicoGqlDatatableRef = ref<any>(null);
const compromissosQueSeraoExecutadosGqlDatatableRef = ref<any>(null);

const drawerStore = useDrawerStore();

async function recarregarDatatables()
{
  await historicoGqlDatatableRef.value?.reload();
  await compromissosQueSeraoExecutadosGqlDatatableRef.value?.reload();
}

async function recarregarDatatableCompromissosQueSeraoExecutados()
{
  await compromissosQueSeraoExecutadosGqlDatatableRef.value?.reload();
}

function abrirDrawer()
{
  drawerStore.push2({
    componentName: 'DrawerForm',
    params: {
      form: 'ExecutarRoboConsultaGcpjForm',
      title: () => 'Executar Robô Consulta GCPJ',
      roles: [],
      initialModel: {
        compromissos: compromissosQueSeraoExecutadosGqlDatatableRef.value.getSelectedRowsIds(),
      },
    },
    onClose: async () =>
    {
      await recarregarDatatables();
    },
  });
}

const compromissosQueSeraoExecutadosDatatableFoiCarregado = ref<boolean>(false);
const temCompromissosParaExecucao = ref<boolean>(false);
const roboEstaEmExecucao = ref<boolean>(false);

const ehPossivelExecutar = computed(() =>
{
  return temCompromissosParaExecucao.value && !roboEstaEmExecucao.value;
});

function onCompromissosQueSeraoExecutadosDatatableLoad(dataSource: any)
{
  temCompromissosParaExecucao.value = dataSource.length > 0;
  compromissosQueSeraoExecutadosDatatableFoiCarregado.value = true;
}

function onExecucoesDatatableLoad(dataSource: any)
{
  roboEstaEmExecucao.value = dataSource.some((x: any) => x.compromisso.status === 1);
}
</script>

<template>
  <Page
    menu="robo-consulta-gcpj"
  >
    <template #default>
      <div>
        <a-row>
          <a-col
            :xs="24"
            :md="24"
            :lg="24"
            :xl="24"
          >
            <!-- Page header -->
            <a-row>
              <a-col :span="12">
                <a-breadcrumb>
                  <a-breadcrumb-item>
                    <router-link to="/robos">
                      Robôs
                    </router-link>
                  </a-breadcrumb-item>
                  <a-breadcrumb-item>
                    <router-link to="/robo/consultar-gcpj">
                      Consultar GCPJ
                    </router-link>
                  </a-breadcrumb-item>
                  <a-breadcrumb-item>Executar</a-breadcrumb-item>
                </a-breadcrumb>
              </a-col>
              <a-col :span="12" />
            </a-row>
            <h1>
              <RobotOutlined style="color: #bebcbc" />
              Consultar GCPJ
            </h1>
          </a-col>
        </a-row>

        <div style="margin-top: 24px">
          <div>
            <a-flex
              v-if="compromissosQueSeraoExecutadosDatatableFoiCarregado"
              justify="flex-end"
            >
              <a-alert
                v-if="ehPossivelExecutar"
                type="warning"
                message="Se nenhum compromisso for selecionado, todos serão executados."
                style="width: 100%; margin-right: 8px"
                show-icon
              />
              <a-alert
                v-if="!temCompromissosParaExecucao"
                type="warning"
                message="Não existem compromissos para serem executados"
                style="width: 100%; margin-right: 8px;"
                show-icon
              />
              <a-alert
                v-if="roboEstaEmExecucao"
                type="error"
                message="O robô está sendo executado"
                style="width: 100%; margin-right: 8px;"
              />
              <a-button
                size="large"
                style="margin-right: 8px;"
                @click="recarregarDatatableCompromissosQueSeraoExecutados"
              >
                <template #icon>
                  <ReloadOutlined />
                </template>
                Recarregar
              </a-button>
              <a-button
                :disabled="!ehPossivelExecutar"
                type="primary"
                size="large"
                @click="abrirDrawer"
              >
                <template #icon>
                  <CaretRightOutlined />
                </template>
                Executar
              </a-button>
            </a-flex>
            <CompromissoGqlDatatable
              key="compromissosQueSeraoExecutados"
              ref="compromissosQueSeraoExecutadosGqlDatatableRef"
              :reload-interval="10000"
              :show-dates-filter="false"
              :show-reload-button="false"
              :show-status-search-bar="false"
              :show-filter="false"
              :total-per-page="5"
              :enable-row-selection="true"
              :variables="{
                order: [{ dataInicio: 'DESC' }],
                where: {
                  tipo: {
                    eq: 158,
                  },
                  status: {
                    eq: 1,
                  },
                },
              }"
              @load="onCompromissosQueSeraoExecutadosDatatableLoad"
              @reload="onCompromissosQueSeraoExecutadosDatatableLoad"
            />
            <a-collapse
              default-active-key="h"
              style="margin-top: 24px"
            >
              <a-collapse-panel
                key="h"
                header="EXECUÇÕES"
              >
                <JobCompromissoGqlDatatable
                  ref="historicoGqlDatatableRef"
                  :reload-interval="10000"
                  :variables="{
                    where: {
                      compromisso: {
                        tipo: {
                          eq: 160,
                        },
                      },
                    },
                    order: {
                      compromisso: {
                        dataInicio: 'DESC',
                      },
                    },
                  }"
                  @load="onExecucoesDatatableLoad"
                  @reload="onExecucoesDatatableLoad"
                />
              </a-collapse-panel>
            </a-collapse>
          </div>
        </div>
      </div>
    </template>
  </Page>
</template>
