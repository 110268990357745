query preencherInformacoesNecessariasDistribuicaoPasta($where: CompromissoModelFilterInput) {
    model: compromisso(
        where: $where
    ) {
        codigo
        fluxo {
            pasta {
                agencia2 {
                    codigo
                    nome
                    email
                    numero
                    regional
                    estado {
                        sigla
                    }
                    porte
                    label
                }
                conta
                contrarioPrincipal {
                    codigo
                    nome
                }
                responsavelNegocial {
                    codigo
                    nome
                }
                gerenteNegocialResponsavel {
                    codigo
                    nome
                }
                responsavelProcessual {
                    codigo
                    nome
                }
                carteirasContratos {
                    codigo
                    contrato
                    original
                    vigente
                    carteira {
                        codigo
                        nome
                        tipo
                        label
                    }
                }
                carteiraContratoVigente {
                    contrato
                    carteira {
                        codigo
                        nome
                        tipo
                        label
                    }
                }
                numeroCnj
            }
        }
    }
}