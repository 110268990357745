<script setup>
import { onMounted } from 'vue';
import { useAuthStore } from '~/stores/auth';
import { formatDate, formatDinheiro } from '~/utils';

const props = defineProps({
  compromisso: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['loaded']);
const authStore = useAuthStore();

onMounted(() =>
{
  emit('loaded');
});
</script>

<template>
  <a-descriptions :column="3" :label-style="{ width: '25%' }" bordered>
    <a-descriptions-item label="Modelo de ajuizamento" :span="3">
      {{ props.compromisso.data.tipoModeloAjuizamentoText }}
    </a-descriptions-item>

    <a-descriptions-item label="Estado" :span="3">
      {{ props.compromisso.data.estadoNome }}
    </a-descriptions-item>

    <a-descriptions-item label="Justiça" :span="3">
      {{ props.compromisso.data.justicaNome }}
    </a-descriptions-item>

    <a-descriptions-item label="Comarca" :span="3">
      {{ props.compromisso.data.comarcaNome }}
    </a-descriptions-item>

    <a-descriptions-item label="Valor causa" :span="3">
      {{ formatDinheiro(props.compromisso.data.valorCausa) }}
    </a-descriptions-item>

    <a-descriptions-item label="Carteira" :span="3">
      {{ props.compromisso.data.carteiraNome }}
    </a-descriptions-item>

    <a-descriptions-item label="Contrato" :span="3">
      {{ props.compromisso.data.contrato }}
    </a-descriptions-item>

    <a-descriptions-item label="Empresa" :span="3">
      {{ props.compromisso.data.empresaText }}
    </a-descriptions-item>

    <a-descriptions-item label="Plano" :span="3">
      {{ props.compromisso.data.plano }}
    </a-descriptions-item>

    <a-descriptions-item label="Agência" :span="3">
      {{ props.compromisso.data.agenciaNome }}
    </a-descriptions-item>

    <a-descriptions-item label="Conta" :span="3">
      {{ props.compromisso.data.conta }}
    </a-descriptions-item>

    <a-descriptions-item label="Garantias" :span="3">
      {{ props.compromisso.data.garantias }}
    </a-descriptions-item>

    <a-descriptions-item label="Avalistas" :span="3">
      {{ props.compromisso.data.avalistas }}
    </a-descriptions-item>

    <a-descriptions-item label="Data vencimento inicial" :span="3">
      {{
        formatDate(
          props.compromisso.data.dataVencimentoInicial,
          authStore.timeZone,
          'DD/MM/YYYY',
        )
      }}
    </a-descriptions-item>

    <a-descriptions-item label="Valor parcela do contrato original" :span="3">
      {{ formatDinheiro(props.compromisso.data.valorParcelaContratoOriginal) }}
    </a-descriptions-item>

    <a-descriptions-item label="Contrário principal" :span="3">
      {{ props.compromisso.data.contrarioPrincipalNome }}
    </a-descriptions-item>

    <a-descriptions-item
      label="Endereço principal do contrário / Descrição"
      :span="3"
    >
      {{ props.compromisso.data.enderecoPrincipalDescricao }}
    </a-descriptions-item>

    <a-descriptions-item
      label="Endereço principal do contrário / Logradouro"
      :span="3"
    >
      {{ props.compromisso.data.enderecoPrincipalLogradouro }}
    </a-descriptions-item>

    <a-descriptions-item
      label="Endereço principal do contrário / Número"
      :span="3"
    >
      {{ props.compromisso.data.enderecoPrincipalNumero }}
    </a-descriptions-item>

    <a-descriptions-item
      label="Endereço principal do contrário / Complemento"
      :span="3"
    >
      {{ props.compromisso.data.enderecoPrincipalComplemento }}
    </a-descriptions-item>

    <a-descriptions-item
      label="Endereço principal do contrário / Bairro"
      :span="3"
    >
      {{ props.compromisso.data.enderecoPrincipalBairro }}
    </a-descriptions-item>

    <a-descriptions-item
      label="Endereço principal do contrário / Cep"
      :span="3"
    >
      {{ props.compromisso.data.enderecoPrincipalCep }}
    </a-descriptions-item>

    <a-descriptions-item
      label="Endereço principal do contrário / Cidade"
      :span="3"
    >
      {{ props.compromisso.data.enderecoPrincipalCidadeNome }}
    </a-descriptions-item>

    <a-descriptions-item
      label="Endereço principal do contrário / Estado"
      :span="3"
    >
      {{ props.compromisso.data.enderecoPrincipalEstadoNome }}
    </a-descriptions-item>

    <a-descriptions-item label="Arquivo gerado" :span="3">
      <a
        target="_blank"
        :href="getGoogleDriveUrl(props.compromisso.data.arquivoAjuizamentoId)"
      >CLIQUE PARA BAIXAR</a>
    </a-descriptions-item>

    <a-descriptions-item label="Arquivos" :span="3">
      <span v-if="props.compromisso.arquivos.length === 0">Nenhum arquivo enviado</span>
      <ul v-else>
        <li
          v-for="arquivo in props.compromisso.arquivos"
          :key="arquivo.fileServiceId"
        >
          <a target="_blank" :href="getGoogleDriveUrl(arquivo.fileServiceId)">{{
            arquivo.nome
          }}</a>
        </li>
      </ul>
    </a-descriptions-item>
  </a-descriptions>
</template>
