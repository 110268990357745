<script setup>
import {
  CloudUploadOutlined,
  DownOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons-vue';
import { ref } from 'vue';
import PERFIL_ACESSO from '~/perfilAcesso';
import { useAuthStore } from '~/stores/auth';
import { useDrawerStore } from '~/stores/drawer';

const drawerStore = useDrawerStore();
const authStore = useAuthStore();
const importacaoJobGraphqlDatatableRef = ref(null);

function abrirImportarPlanilhaAlteracaoPastaForm(_)
{
  drawerStore.push2({
    componentName: 'ImportarPlanilhaAlteracaoPastaForm',
    params: {},
    width: 1000,
    onClose: async () =>
    {
      await importacaoJobGraphqlDatatableRef.value?.reloadAsync();
    },
  });
}

async function importarMenuClick({ key })
{
  if (key === 'planilha-alteracao-pasta')
  {
    abrirImportarPlanilhaAlteracaoPastaForm();
  }
}
</script>

<template>
  <Page menu="importacoes">
    <div>
      <a-row justify="center">
        <a-col
          :xs="24"
          :md="24"
          :lg="24"
          :xl="24"
        >
          <!-- Page header -->
          <a-row>
            <a-col :span="12">
              <a-breadcrumb>
                <a-breadcrumb-item>
                  <router-link to="/importacoes">
                    Importações
                  </router-link>
                </a-breadcrumb-item>
                <a-breadcrumb-item>Buscar</a-breadcrumb-item>
              </a-breadcrumb>
              <h1>
                <UnorderedListOutlined
                  style="color: #bebcbc; font-size: 18px"
                />
                Buscar importações
              </h1>
            </a-col>
            <a-col :span="12">
              <div class="page-header-button-container" />
            </a-col>
          </a-row>
          <!-- /Page header -->
          <ImportacaoJobGraphqlDatatable ref="importacaoJobGraphqlDatatableRef">
            <template #leftTopActions>
              <a-col flex="100px">
                <a-dropdown>
                  <template #overlay>
                    <a-menu @click="importarMenuClick">
                      <a-menu-item
                        v-if="
                          authStore.temPerfilAcesso(
                            PERFIL_ACESSO.ImportarPlanilhaAlteracaoPasta,
                          )
                        "
                        key="planilha-alteracao-pasta"
                      >
                        Planilha de alteração de pasta
                      </a-menu-item>
                    </a-menu>
                  </template>
                  <a-button size="large">
                    <template #icon>
                      <CloudUploadOutlined />
                    </template>
                    Importar
                    <DownOutlined />
                  </a-button>
                </a-dropdown>
              </a-col>
            </template>
          </ImportacaoJobGraphqlDatatable>
        </a-col>
      </a-row>
    </div>
  </Page>
</template>
