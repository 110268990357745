<script setup lang="ts">
import { ReloadOutlined } from '@ant-design/icons-vue';

import { useAuthenticationStore } from '~/stores/authenticationStore';

interface Props
{
  allow?: number[]
}

const props = defineProps<Props>();

const authStore = useAuthenticationStore();

function tentarNovamente()
{
  window.location.reload();
}
</script>

<template>
  <slot
    v-if="authStore.hasRoles(props.allow ?? [])"
    name="authorized"
  />
  <slot
    v-else
    name="not-authorized"
  >
    <a-result
      status="403"
      title="Você não tem permissão para acessar"
      sub-title="Entre em contato com a equipe de suporte"
    >
      <template #extra>
        <a-button
          type="primary"
          @click="() => tentarNovamente()"
        >
          <template #icon>
            <ReloadOutlined />
          </template>
          Tentar novamente
        </a-button>
      </template>
    </a-result>
  </slot>
</template>
