<script setup lang="ts">
import { CloseOutlined } from '@ant-design/icons-vue';

interface Props
{
  title: string
  closable?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  closable: true,
});

const emit = defineEmits(['close']);
</script>

<template>
  <a-row
    class="drawer-title"
    style="padding-top: 16px; padding-left: 24px; padding-right: 24px"
  >
    <a-col :span="22">
      <a-space>
        <slot name="icon" />
        <span style="text-transform: uppercase; font-size: 16px; font-weight: 600;">{{ props.title }}</span>
      </a-space>
    </a-col>
    <a-col
      :span="2"
      style="text-align: right"
    >
      <CloseOutlined
        v-if="props.closable"
        @click="emit('close')"
      />
    </a-col>
  </a-row>
  <a-divider style="margin-top: 16px" />
</template>
