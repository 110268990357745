<script setup lang="ts">
import { SaveOutlined } from '@ant-design/icons-vue';
import { notification } from 'ant-design-vue';
import { isEqual } from 'lodash-es';
import { reactive, ref, toRaw, watch } from 'vue';

import { useCommand } from '~/composables/useCommand';

interface Props
{
  command: string
  initialModel?: object
  preSubmit?: any
  postSubmit?: any
  submitButtonText?: string
  showSubmitButtonIcon?: boolean
  showSubmitSuccessResult?: boolean
  showSubmitErrorNotification?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  submitButtonText: 'Salvar',
  showSubmitButtonIcon: true,
  showSubmitSuccessResult: true,
  showSubmitErrorNotification: true,
});

const emit = defineEmits(['success', 'error']);

const { status: commandStatus, command: execute, data: commandData, err: commandErr } = useCommand({
  config: {
    getAccessToken: true,
  },
});

const formRef = ref<any>();
const submitStatusRef = ref<'loading' | 'success' | 'error' | null>(null);
const originalModel: any = {
  ...props.initialModel,
};
const model = reactive<any>(props.initialModel ?? {});
let resultModel: any = {};

async function submit()
{
  submitStatusRef.value = 'loading';
  await formRef.value.validate();

  if (props.preSubmit)
  {
    props.preSubmit(model);
  }

  resultModel = {
    ...toRaw(model),
  };

  const commandData: any = {};

  Object.keys(resultModel).forEach((key: string) =>
  {
    if (!(key in originalModel))
    {
      commandData[key] = {
        changed: true,
        value: resultModel[key],
      };
    }
    else
    {
      const changed = !isEqual(resultModel[key], originalModel[key]);
      commandData[key] = {
        changed,
        value: resultModel[key],
      };
    }
  });

  await execute(props.command, commandData);
}

watch(commandStatus, (val: any) =>
{
  if (val === 'success')
  {
    submitStatusRef.value = 'success';
    emit('success', toRaw(commandData.value));
  }

  if (val === 'error')
  {
    console.log(commandErr.value);
    if (commandErr.value?.status === 400)
    {
      emit('error', commandErr.value);
      if (props.showSubmitErrorNotification)
      {
        notification.error({
          message: commandErr.value.message,
          duration: 10,
        });
      }
    }
    else
    {
      emit('error', commandErr.value);
      if (props.showSubmitErrorNotification)
      {
        notification.error({
          message: 'Tivemos um erro inesperado',
          description: 'Favor entrar em contato com a equipe de suporte',
          duration: 10,
        });
      }
    }

    submitStatusRef.value = 'error';
  }

  if ((val === 'success' || val === 'error') && props.postSubmit)
  {
    props.postSubmit(submitStatusRef.value, model);
  }
});

defineExpose({
  model,
});
</script>

<template>
  <a-form
    v-if="!props.showSubmitSuccessResult || commandStatus !== 'success'"
    ref="formRef"
    :model="model"
    autocomplete="off"
    layout="vertical"
  >
    <slot
      name="form"
      :model="model"
    />
    <a-form-item>
      <slot
        name="submitButton"
        :command-status="commandStatus"
        :submit="submit"
      >
        <a-button
          block
          type="primary"
          :loading="commandStatus === 'loading'"
          size="large"
          html-type="submit"
          @click.prevent="submit"
        >
          <template
            v-if="props.showSubmitButtonIcon"
            #icon
          >
            <slot name="submit-button-icon">
              <SaveOutlined />
            </slot>
          </template>
          {{ props.submitButtonText }}
        </a-button>
      </slot>
    </a-form-item>
  </a-form>

  <slot
    v-if="props.showSubmitSuccessResult && commandStatus === 'success'"
    name="submitSuccess"
    :data="commandData"
  />
</template>
