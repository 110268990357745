<script setup lang="ts">
import { ref } from 'vue';
import { sleep } from '~/utils';

interface Props
{
  initialModel?: any
}

const props = defineProps<Props>();

const emit = defineEmits(['close']);

const submitSuccess = ref(false);

async function postSubmit()
{
  await sleep(5000);
}

function onSubmitSuccess()
{
  submitSuccess.value = true;
}
</script>

<template>
  <CompromissoJobCommandForm
    command="ExecutarRoboConsultaGcpjCommand"
    :show-upload-files="false"
    :initial-model="props.initialModel"
    :wait-for-result="false"
    :post-submit="postSubmit"
    @close="() => emit('close')"
    @success="onSubmitSuccess"
  >
    <template #form="{ model }">
      <a-alert
        type="warning"
        :message="model.compromissos.length === 0 ? 'Todos os compromissos serão executados.' : `Você selecionou ${model.compromissos.length} compromisso(s) para execução.`"
        style="margin-bottom: 24px"
        show-icon
      />

      <a-form-item
        label="Cookie"
        name="cookie"
        :rules="{
          message: 'Cookie é obrigatório',
          required: true,
        }"
      >
        <a-input
          v-model:value="model.cookie"
        />
      </a-form-item>
    </template>
  </CompromissoJobCommandForm>
</template>
