<script setup lang="ts">
import { ref } from 'vue';

const emit = defineEmits(['close']);

const planilhaRef = ref<any>(null);

async function preSubmit(_: any)
{
  await planilhaRef.value.upload();
  return true;
}
</script>

<template>
  <CompromissoJobCommandForm
    command="ImportarPastasPlanilhaBradescoCommand"
    :show-upload-files="false"
    :pre-submit="preSubmit"
    @close="() => emit('close')"
  >
    <template #form="{ model }">
      <a-alert
        show-icon
        style="margin-bottom: 24px"
      >
        <template #message>
          <a
            target="_blank"
            href="/TEMPLATE_IMPORTACAO_PASTA.xlsx"
          >Clique aqui para baixar o template da planilha de importação</a>
        </template>
      </a-alert>

      <a-form-item
        label="Data remessa"
        name="dataRemessa"
        :rules="{
          message: 'Data da remessa é obrigatória',
          required: true,
          trigger: 'change',
        }"
      >
        <a-date-picker
          v-model:value="model.dataRemessa"
          format="DD/MM/YYYY"
          value-format="DD/MM/YYYY"
          placeholder="Selecione uma data"
          style="width: 100%"
        />
      </a-form-item>
      <a-form-item
        label="Planilha"
        name="planilha"
        :rules="{
          message: 'Planilha é obrigatória',
          required: true,
          trigger: 'change',
          type: 'object',
        }"
      >
        <UploadFile
          ref="planilhaRef"
          v-model:file="model.planilha"
        />
      </a-form-item>
    </template>
  </CompromissoJobCommandForm>
</template>
