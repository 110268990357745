<script setup lang="ts">
import { onMounted } from 'vue';

interface Props
{
  compromisso?: any
}

const props = defineProps<Props>();

const emit = defineEmits(['loaded']);

onMounted(() =>
{
  emit('loaded');
});
</script>

<template>
  <a-descriptions
    :column="3"
    :label-style="{ width: '30%' }"
    bordered
    style="margin-top: 24px"
  >
    <a-descriptions-item
      label="Agência"
      :span="3"
    >
      {{ props.compromisso.data.agencia }}
    </a-descriptions-item>
    <a-descriptions-item
      label="Email da agência"
      :span="3"
    >
      {{ props.compromisso.data.agenciaEmail }}
    </a-descriptions-item>
    <a-descriptions-item
      label="Conta"
      :span="3"
    >
      {{ props.compromisso.data.conta }}
    </a-descriptions-item>
    <a-descriptions-item
      label="Contrário principal"
      :span="3"
    >
      {{ props.compromisso.data.contrarioPrincipal }}
    </a-descriptions-item>
    <a-descriptions-item
      label="Responsável negocial"
      :span="3"
    >
      {{ props.compromisso.data.responsavelNegocial }}
    </a-descriptions-item>
    <a-descriptions-item
      label="Geren. negocial responsável"
      :span="3"
    >
      {{ props.compromisso.data.gerenteNegocialResponsavel }}
    </a-descriptions-item>
    <a-descriptions-item
      label="Responsável processual"
      :span="3"
    >
      {{ props.compromisso.data.responsavelProcessual }}
    </a-descriptions-item>
    <a-descriptions-item
      label="Carteira"
      :span="3"
    >
      {{ props.compromisso.data.carteira }}
    </a-descriptions-item>
    <a-descriptions-item
      label="Contrato"
      :span="3"
    >
      {{ props.compromisso.data.contrato }}
    </a-descriptions-item>
  </a-descriptions>
</template>
