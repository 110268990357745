import { defineStore } from 'pinia';
import axios from 'axios';
import { useAuthenticationStore } from './authenticationStore';
import router from '~/router';
import { API_URL, isNil } from '~/utils';
import { refreshToken } from '~/apis/refresh_token';

const EXPIRACAO_ACCESS_TOKEN_EM_MINUTOS = Number.parseInt(
  import.meta.env.VITE_EXPIRACAO_ACCESS_TOKEN_EM_MINUTOS,
  10,
);

const timeZoneMap = {
  'SA Western Standard Time': 'America/Manaus',
  'E. South America Standard Time': 'America/Sao_Paulo',
};

async function logout()
{
  const response = await axios.post(
    `${API_URL}/autenticacao/logout`,
    {},
    {
      withCredentials: true,
      timeout: 120000,
    },
  );
  return response.data;
}

export const useAuthStore = defineStore('auth', {
  state: () => ({
    status: 'loading',
    logoutStatus: null,
    accessToken: null,
    codigoUsuario: null,
    nomeUsuario: null,
    iniciaisUsuario: null,
    perfisAcesso: [],
    ehAdvogado: false,
    ehGerente: false,
    ehGestor: false,
    refreshTokenTimeout: null,
    timeZone: undefined,
  }),
  getters: {
    usuario(state)
    {
      return {
        codigoUsuario: state.codigoUsuario,
        ehGerente: state.ehGerente,
        ehGestor: state.ehGestor,
        ehAdvogado: state.ehAdvogado,
      };
    },
    ehSomenteAdvogado(state)
    {
      return state.ehAdvogado && !state.ehGestor && !state.ehGerente;
    },
    temPerfilAcesso(state)
    {
      return (perfil) =>
      {
        if (Array.isArray(perfil))
        {
          return perfil.some(p => state.perfisAcesso.includes(p));
        }
        return state.perfisAcesso.includes(perfil);
      };
    },
  },
  actions: {
    success()
    {
      this.status = 'success';
    },
    isSuccess()
    {
      return this.status === 'success';
    },
    error()
    {
      this.status = 'error';
    },
    loading()
    {
      this.status = 'loading';
    },

    defUsuario(usuario)
    {
      this.codigoUsuario = usuario.codigo;
      this.nomeUsuario = usuario.nome;
      this.perfisAcesso = usuario.perfisAcesso;
      this.ehAdvogado = usuario.ehAdvogado;
      this.ehGestor = usuario.ehGestor;
      this.ehGerente = usuario.ehGerente;
      if (usuario.nome)
      {
        const nomes = usuario.nome.split(' ');
        const iniciais = `${nomes[0][0]}${nomes[nomes.length - 1][0]}`;
        this.iniciaisUsuario = iniciais;
      }
      else
      {
        this.iniciaisUsuario = 'ER';
      }
      this.timeZone = !isNil(usuario.timeZone)
        ? timeZoneMap[usuario.timeZone]
        : 'America/Manaus';

      const authenticationStore = useAuthenticationStore();
      authenticationStore.set({
        id: usuario.codigo,
        name: usuario.nome,
        roles: usuario.perfisAcesso,
      });
    },

    ehPath(urlPath)
    {
      const paths = [
        '/usuarios/esqueci-senha',
        '/usuarios/resetar-senha',
        '/login',
        '/usuarios/criar-senha',
      ];
      return paths.findIndex(p => p === urlPath) >= 0;
    },

    async refreshToken()
    {
      try
      {
        const response = await refreshToken();
        this.accessToken = response.accessToken;
        this.defUsuario({
          codigo: response.codigoUsuario,
          nome: response.nomeUsuario,
          perfisAcesso: response.perfisAcesso,
          ehAdvogado: response.ehAdvogado,
          ehGestor: response.ehGestor,
          ehGerente: response.ehGerente,
          timeZone: response.timeZone,
        });
      }
      catch (e)
      {
        this.resetar();
        if (!this.ehPath(window.location.pathname))
        {
          router.push('/login');
        }
      }
    },

    iniciarTimeoutRefreshToken()
    {
      const timeout
        = EXPIRACAO_ACCESS_TOKEN_EM_MINUTOS === 1
          ? 30 * 1000
          : (EXPIRACAO_ACCESS_TOKEN_EM_MINUTOS - 5) * 60 * 1000;

      this.refreshTokenTimeout = setTimeout(async () =>
      {
        await this.refreshToken();
        if (this.accessToken !== null)
        {
          this.iniciarTimeoutRefreshToken();
        }
      }, timeout);
    },

    resetar()
    {
      if (this.refreshTokenTimeout !== null)
      {
        clearTimeout(this.refreshTokenTimeout);
      }
      this.$reset();
    },

    async logout()
    {
      if (this.refreshTokenTimeout !== null)
      {
        clearTimeout(this.refreshTokenTimeout);
      }

      this.logoutStatus = 'loading';
      try
      {
        await logout();
      }
      catch
      {
        this.logoutStatus = 'error';
      }
      finally
      {
        this.$reset();
      }
    },
  },
});
