<script setup lang="ts">
import type { DocumentNode } from 'graphql/index';

interface Props
{
  form: string
  title: any
  roles?: number[]
  initialModel?: any
  gql?: DocumentNode
  gqlVariables?: any
  onGqlSuccess?: any
}

const props = withDefaults(
  defineProps<Props>(),
  {
    initialModel: () => ({}),
    onGqlSuccess: (data: any) =>
    {
      return data;
    },
  },
);

const emit = defineEmits(['close', 'success']);
</script>

<template>
  <AuthorizeView :allow="props.roles ?? []">
    <template #authorized>
      <div
        v-if="!props.gql"
      >
        <DrawerTitle2
          :title="props.title()"
          @close="() => emit('close')"
        />
        <div style="padding-left: 24px; padding-right: 24px; padding-bottom: 24px">
          <component
            :is="props.form"
            :initial-model="props.initialModel"
            @close="() => emit('close')"
          />
        </div>
      </div>
      <GqlView
        v-else
        :query="props.gql"
        :variables="props.gqlVariables"
      >
        <template #loading>
          <DrawerComponentLoading />
        </template>
        <template #success="{ data }">
          <DrawerTitle2
            :title="props.title((data as any).data)"
            @close="() => emit('close')"
          />
          <div style="padding-left: 24px; padding-right: 24px; padding-bottom: 24px">
            <component
              :is="props.form"
              :initial-model="{ ...props.onGqlSuccess((data as any).data), ...props.initialModel }"
              @close="() => emit('close')"
            />
          </div>
        </template>
      </GqlView>
    </template>
  </AuthorizeView>
</template>
